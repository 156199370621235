<template>
  <validation-observer ref="formValidation">
    <b-form>
      <b-row class="align-content-center mx-auto d-flex flex-column">
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Nombre"
            label-for="first_name"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-input
                id="first_name"
                v-model="first_name"
                :state="errors.length > 0 ? false:null"
                placeholder="Nombre"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Apellido"
            label-for="last_name"
          >
            <validation-provider
              #default="{ errors }"
              name="apellido"
              rules="required"
            >
              <b-form-input
                id="last_name"
                v-model="last_name"
                :state="errors.length > 0 ? false:null"
                placeholder="Apellido"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Email"
            label-for="emailValue"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                v-model="emailValue"
                :state="errors.length > 0 ? false:null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Compañías"
            label-for="companies"
          >
            <validation-provider
              #default="{ errors }"
              name="compañías"
              rules="required"
            >
              <v-select
                v-model="companies"
                :dir="'ltr'"
                label="name"
                :state="errors.length > 0 ? false : null"
                :options="rowsCompanies"
                multiple
                :select-size="6"
              >
                <span slot="no-options">No hay data para cargar</span>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="id === undefined"
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Contraseña"
            label-for="password"
          >
            <validation-provider
              #default="{ errors }"
              name="contraseña"
              rules="required"
            >
              <b-form-input
                v-model="password"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Contraseña"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="id === undefined"
          lg="8"
          md="10"
          sm="12"
        >
          <b-form-group
            label="Confirmación de contraseña"
            label-for="password_confirmation"
          >
            <validation-provider
              #default="{ errors }"
              name="confirmación de contraseña"
              rules="required"
            >
              <b-form-input
                v-model="password_confirmation"
                :state="errors.length > 0 ? false:null"
                type="text"
                placeholder="Confirmación de contraseña"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          lg="8"
          md="10"
          sm="12"
        >
          <b-button
            v-if="id === undefined ? accessPermissions('users.global.store') : accessPermissions('users.global.update')"
            class="float-right text-right"
            variant="primary"
            type="submit"
            @click.prevent="onHandleValidationForm"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="float-right mr-1"
            :to="{ name: 'settings-agents' }"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import router from '@/router'
import UsersServices from '../services/UsersServices'
import CompaniesServices from '@/pages/dashboard/settings/companies/services/CompaniesServices'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormCreateComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      first_name: '',
      last_name: '',
      emailValue: '',
      companies: '',
      rowsCompanies: [],
      password: '',
      password_confirmation: '',
      required,
      email,
    }
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.first_name = ''
      this.last_name = ''
      this.emailValue = ''
      this.companies = ''
      this.rowsCompanies = []
      this.password = ''
      this.password_confirmation = ''
      this.$refs.formValidation.reset()
    },
    async onHandleEdit() {
      await this.onHandleCompaniesList()

      if (this.id !== undefined && this.accessPermissions('users.global.show')) {
        await UsersServices.handleEdit(this.id).then(response => {
          const companies = []

          if (response.data.data.companies !== null) {
            const arrayCategory = response.data.data.companies

            arrayCategory.map(({ id, name_company }) => {
              companies.push({ id: id, name: name_company })
            })
          }

          this.first_name = response.data.data.profile.first_name
          this.last_name = response.data.data.profile.last_name
          this.emailValue = response.data.data.email
          this.companies = companies
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleCompaniesList() {
      await CompaniesServices.handleList().then(response => {
        const companies = []

        if (response.data.data.length > 0) {
          response.data.data.map(({ id, name_company }) => {
            companies.push({ id: id, name: name_company })
          })
        }

        this.rowsCompanies = companies
      }).catch(error => this.errorResp(error))
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const companies = []

          if (this.companies.length > 0) {
            this.companies.map(({ id }) => {
              companies.push(id)
            })
          }

          if (this.id === undefined && this.accessPermissions('users.global.store')) {
            const data = {
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.emailValue,
              companies: companies,
              password: this.password,
              password_confirmation: this.password_confirmation,
              dni_type: 1,
              dni_profile: 1,
            }

            UsersServices.handleStore(data).then(response => {
              if (response.data.data) {
                this.swal('El usuario ha sido registrado correctamente', 'success')
                this.clearForm()
                router.push({ name: 'settings-users' })
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('users.global.update')) {
            const data = {
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.emailValue,
              companies: companies,
              dni_type: 1,
              dni_profile: 1,
            }

            UsersServices.handleUpdate(this.id, data).then(response => {
              if (response.data.data) {
                this.swal('El usuario ha sido actualizado correctamente', 'success')
                this.clearForm()
                router.push({ name: 'settings-users' })
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
